<template>
    <div>
        <v-flex xs12 sm8 offset-sm-2 class="my-4 px-8">
        <v-card class="white--text mb-1 mt-8 " flat :tile="true">
          <v-btn
            fab
            small
            color="primary accent-2"
            bottom
            right
            absolute
            @click="goHome"
          >
            <v-icon>mdi-home</v-icon>
          </v-btn>
  
          <v-container fluid grid-list-lg>
            <v-layout row>
              <v-flex xs7>
                <div>
                  <div class="display-1 black--text text--darken-1">About AkokoMarket</div>
  <!--                <div class="grey&#45;&#45;text">-->
  <!--                    Your one stop shop for all poultry products-->
  <!--                </div>-->
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
  
        <v-card color="white mb-15" flat class="white--text" height="100%">
  
          <!--comes here-->
          <v-card flat>
            <v-card-text>
              <!--//Tab contents-->
              <v-container fluid grid-list-lg class="">
                <v-layout row>
                  <v-flex xs12>
                    <div class="">
                    <div class="mb-8">
                        <v-img src="@/assets/imgs/about.jpg"></v-img>
                    </div>
                      <div class="headline primary--text">We are Akokomarket</div>
                      <!-- <blockquote class="grey--text text--darken-1">
                        Established in the year 2019, Akokomarket limited aims to
                          Agro Innova is an agritech company that focuses on using
                          digital technologies to tackle problems in the agricultural
                          value chain. Our company deploys a suite of software tools and
                          technology-enabled platforms which help to streamline various
                          processes across the agricultural sector.
  
  
                          <v-divider class="my-4"></v-divider>
  
                        
  
                          <v-divider class="my-4"></v-divider>
  
                        <div class="headline primary--text">Mission</div>
  
                        
  
                          <v-divider class="my-4"></v-divider>
  
                        <div class="headline primary--text">Vision</div>
  
                        Our vision is to become the market leader in the poultry products
                        industry in Ghana and West Africa Sub-region.
                          <v-divider class="my-4"></v-divider>
                        <div class="headline primary--text">Beliefs</div>
                        We consider the following as the critical success factor
                        for the provision of sustainable agricultural products to our
                        clients and appropriate strategies have been formulated to
                        attain these:
                        <br />
                        <br />
                        1. Safety and Reliability of our delivery services.
                        <br />
  
                        2. Effective maintenance of products
                        <br />
  
                        3. Agent commitment
                        <br />
  
                        4. Competitive daily prices
                        <br />
  
                        5. Professionalism and competent management
                        <br />
                        <br />
                      </blockquote> -->
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-card>
      </v-flex>
    </div>
  </template>
  
  <script>
  export default {
    name: "about_us",
  
    data: () => ({
      items: [
        { txt: "We are Akokomarket", icon: "mdi-car-sports", set: true },
        { txt: "Our Management Team", icon: "mdi-account", set: false }
      ],
      text: "",
      gold_mgt: true,
      henry: false,
      felix: false,
      violet: false,
      enyo: false
    }),
  
    methods: {
      goHome() {
        this.$router.push("/");
      },
    //   addItem(item) {
    //     const removed = this.items.splice(0, 1);
    //     this.items.push(...this.more.splice(this.more.indexOf(item), 1));
    //     this.more.push(...removed);
    //     this.$nextTick(() => {
    //       this.currentItem = "tab-" + item;
    //     });
    //     alert(this.currentItem);
    //   }
    },
  };
  </script>
  
  <style scoped></style>
  